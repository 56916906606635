<template>
<div class="pageInner1">
<div class="pageInner2">
    <div class="intro">
        <div class="title noDrag"><span class="h3Line"></span>정보</div>
        <div class="introInner1">
            <div class="introInner2">
                <div><p>이름</p>박서준 (Park Seo Jun)</div>
                <div><p>나이</p>19세</div>
                <div><p>학력</p>군서고등학교 졸업 (2025. 02)</div>
                <div><p>학력</p>서울신학대 재학 (2025. 03)</div>
            </div>
            <div class="img noDrag"><img src="img/seojun2.png" loading="lazy"></div>
        </div>
    </div>
    <div class="info">
        <div class="title noDrag"><span class="h3Line"></span>소개</div>
        <p>
            풀 스택 개발자 입니다.
        </p>
    </div>  
    <div class="info">
        <div class="title noDrag"><span class="h3Line"></span>경력</div>
        <p>
            하이디스플레이 외주<br>
            컴세바 송도본점, 대치동 강사경력 ( 2025. 01 ~ )<br>
        </p>
    </div>
    <div class="info">
        <div class="title noDrag"><span class="h3Line"></span>자격증 취득</div>
        <p>
            컴퓨터그래픽스운용기능사<br>
            GTQ 포토샵 1급<br>
            GTQ 일러스트 1급<br>
            SW 코딩자격 1급<br>
            ITQ 파워포인트 A, 인터넷 A, 엑셀A<br>
            ITQ OA MASTER<br>
            ICDL(이미지 프로세싱, 컴퓨터 활용 및 파일관리)<br>
            ICDL(프리젠테이션, 워드프로세싱, 스프레드시트)<br>
        </p>
    </div>
    <div class="info">
        <div class="title noDrag"><span class="h3Line"></span>경시대회</div>
        <p>
            2023 전국 서울특별시 ICT경진대회 미디어콘텐츠부분 금상<br>
            2023 I-TOP 경진대회 일러스트레이터 부분 출전<br>
        </p>
    </div>
    <div class="skillOutter noDrag">
        <div class="title"><span class="h3Line"></span>프로그래밍 기술</div>
        <div class="skills">
            <div class="skill">
                <span>Python</span>
                <div class="progress">
                    <div class="progressBg" style="width: 70%"></div>
                </div>
            </div>
            <div class="skill">
                <span>HTML</span>
                <div class="progress">
                    <div class="progressBg" style="width: 90%"></div>
                </div>
            </div>
            <div class="skill">
                <span>CSS</span>
                <div class="progress">
                    <div class="progressBg" style="width: 80%"></div>
                </div>
            </div>
            <div class="skill">
                <span>JS</span>
                <div class="progress">
                    <div class="progressBg" style="width: 90%"></div>
                </div>
            </div>
            <div class="skill">
                <span>React</span>
                <div class="progress">
                    <div class="progressBg" style="width: 85%"></div>
                </div>
            </div>
            <div class="skill">
                <span>React Native</span>
                <div class="progress">
                    <div class="progressBg" style="width: 85%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<style scoped src="../../public/css/aboutPage.css"></style>